import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import { toast } from '@stardust-ds/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import Axios from '../../config/Axios';
import './styles/modal-history.scss';
import imgfLoading from '../../assets/img/preloader-black.gif';
import SearchIcon from '../../assets/icons/SearchIcon';
import lodash from 'lodash';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();

class ModalHistory extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      aHistory: [],
      oPagination: {
        nPage: 1,
        nTotalPages: 1
      },
      bLoading: false
    };

    this.CUSTOMER_FIELDS_TRANSLATION_MAP = {
      cust_clogo: 'Logo',
      cust_cemail: 'E-mail',
      cust_ctype: intl.get('Customers.tipo_cadastro'),
      cust_cname: intl.get('Customers.cnome'),
      cust_ccpf: intl.get('Customers.ccpf'),
      cust_ccnpj: intl.get('Customers.ccnpj'),
      cust_cfantasyname: intl.get('Customers.cnomefantasia'),
      cust_cphone: intl.get('Customers.ctelefone'),
      cust_ccellphone: intl.get('Customers.ccelular'),
      cust_etaxframework: intl.get('Customers.eenquadramentofiscal'),
      cApiKey: intl.get('ChatGpt.chave_api'),
      n: intl.get('ChatGpt.numero_resp'), // chat gpt number of responses
      max_tokens: intl.get('ChatGpt.numero_tokens'), // chat gpt number max tokens
      cust_cpostalcode: intl.get('Address.ccep'),
      cust_cstreet: intl.get('Address.clogradouro'),
      cust_cnumber: intl.get('Address.cnumero'),
      cust_ccomplement: intl.get('Address.ccomplemento'),
      cust_cdistrict: intl.get('Address.cbairro'),
      cust_ccountry: intl.get('Address.cpais'),
      cust_cstate: intl.get('Address.cestado'),
      cust_ccity: intl.get('Address.ccidade')
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.bIsOpen !== this.props.bIsOpen && newProps.bIsOpen) {
      this.handleGetHistory();
    }
  }

  handleCloseModal = () => {
    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  handleGetHistory = (nPage = 1, cSearch = null) => {
    if (nPage <= 1) this.setState({ aHistory: [] });
    this.setState({ bLoading: true });

    Axios.get(`/customer/history?page=${nPage}${cSearch ? `&search_text=${cSearch}` : ''}`)
      .then(response => {
        const aData = response?.data?.data;
        const oPagination = {
          nPage: response?.data?.current_page,
          nTotalPages: response?.data?.last_page
        };

        this.setState({ aHistory: [...this.state.aHistory, ...aData], bLoading: false, oPagination });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        toast({
          type: 'error',
          title: intl.get('Customers.ModalHistory.title'),
          description: oError?.response?.data?.message || intl.get('Customers.ModalHistory.list_error')
        });
      });
  };

  handleSearchHistory = lodash.debounce(cSearch => this.handleGetHistory(1, cSearch), 300);

  handleOnNextPage = () => {
    const nPage = this.state.oPagination?.nPage + 1;
    this.setState({ oPagination: { ...this.state.oPagination, nPage } });
    this.handleGetHistory(nPage);
  };

  cHistoryAction = oHistory => {
    const caction = oHistory?.custhist_caction;
    if (!caction) return '';

    const bIsCustomerApplication = oHistory?.custhist_cfield === 'customer_application';

    // updated customer
    if (caction === 'update') return intl.get('Customers.ModalHistory.update_data');

    // created customer application
    if (bIsCustomerApplication && caction === 'created')
      return intl.get('Customers.ModalHistory.create_customer_application');

    // revoked customer application access token
    if (caction === 'revoked') return intl.get('Customers.ModalHistory.revoke_customer_application');

    if (caction === 'created') return intl.get('Customers.ModalHistory.customer_criation');

    return caction;
  };

  cHistoryFields = oHistory => {
    if (!oHistory) return '';

    const { custhist_cfield, custhist_cold_value, custhist_cnew_value, custhist_caction } = oHistory;

    const bIsCustomerApplication = custhist_cfield === 'customer_application';

    if (bIsCustomerApplication) {
      const oCustApp = JSON.parse(custhist_cnew_value);
      const cCustAppName = oCustApp?.custapp_cname;

      // created customer application
      if (custhist_caction === 'created')
        return (
          <>
            {intl.get('Customers.Applications_tokens.title').toUpperCase()} <b>{cCustAppName?.toUpperCase()}</b>{' '}
            {intl.get('Customers.ModalHistory.created')}
          </>
        );

      // revoked customer application access token
      if (custhist_caction === 'revoked')
        return (
          <>
            {intl.get('Customers.Applications_tokens.title').toUpperCase()} <b>{cCustAppName?.toUpperCase()}</b>{' '}
            {intl.get('Customers.ModalHistory.revoked')}
          </>
        );
    }
    const bIsChatGpt = oHistory?.custhist_cfield === 'cust_ckeyapigpt';
    if (bIsChatGpt) {
      const oOldValuesChatGpt = JSON.parse(JSON.parse(custhist_cold_value));
      const oNewValuesChatGpt = JSON.parse(JSON.parse(custhist_cnew_value));

      const fieldsToCompare = ['max_tokens', 'n', 'cApiKey'];
      const difValues = fieldsToCompare.filter(field => oOldValuesChatGpt?.[field] != oNewValuesChatGpt?.[field]);

      if (custhist_caction === 'update' && difValues.length > 0) {
        return (
          <>
            {difValues.map((field, index) => {
              const cOldValue = oOldValuesChatGpt?.[field];
              const cNewValue = oNewValuesChatGpt?.[field];

              if (!cOldValue && !cNewValue) return null;

              if (cOldValue === undefined || cOldValue === null) {
                return (
                  <Fragment key={`customer-history-chat-item-${index}`}>
                    {intl.get('Customers.ModalHistory.insertValue')} <b>{cNewValue}</b>{' '}
                    {intl.get('Customers.ModalHistory.in')} {this.CUSTOMER_FIELDS_TRANSLATION_MAP[field]}
                    <br />
                  </Fragment>
                );
              }

              return (
                <Fragment key={`customer-history-chat-item-${index}`}>
                  {this.CUSTOMER_FIELDS_TRANSLATION_MAP[field]} {intl.get('Customers.ModalHistory.change_from')}{' '}
                  <b>{cOldValue}</b> {intl.get('Customers.ModalHistory.to')} <b>{cNewValue}</b>
                  <br />
                </Fragment>
              );
            })}
          </>
        );
      }
    }

    // updated customer field
    if (custhist_caction === 'update' && custhist_cfield) {
      if (custhist_cfield === 'cust_clogo') {
        return intl.get('Customers.ModalHistory.updated_logo');
      }

      const cMapedField = this.CUSTOMER_FIELDS_TRANSLATION_MAP[custhist_cfield];
      return (
        <>
          {cMapedField || custhist_cfield} {intl.get('Customers.ModalHistory.change_from')} <b>{custhist_cold_value}</b>{' '}
          {intl.get('Customers.ModalHistory.to')} <b>{custhist_cnew_value}</b>
        </>
      );
    }

    // created customer
    if (custhist_caction === 'created') return intl.get('Customers.ModalHistory.created_customer');
  };

  render() {
    const { aHistory, bLoading, oPagination } = this.state;
    const { bIsOpen } = this.props;

    if (!bIsOpen) return null;

    return (
      <div className="modal-history-overlay">
        <div className="modal-history-container">
          <div className="modal-history-header">
            <h3>{intl.get('Customers.ModalHistory.title')}</h3>
            <span className="modal-history-close" onClick={this.handleCloseModal}>
              &times;
            </span>
          </div>

          <InfiniteScroll
            next={this.handleOnNextPage}
            hasMore={oPagination?.nPage < oPagination?.nTotalPages}
            dataLength={aHistory?.length}
            height={500}
          >
            <div className="input-search-history">
              <span className="icon-search-history">
                <SearchIcon />
              </span>
              <input
                placeholder={intl.get('Customers.ModalHistory.search')}
                onChange={e => this.handleSearchHistory(e?.target?.value)}
              />
            </div>
            <div className="modal-history-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>{intl.get('Customers.ModalHistory.date')}</th>
                    <th>{intl.get('Customers.ModalHistory.user')}</th>
                    <th>{intl.get('Customers.ModalHistory.field')}</th>
                    <th>{intl.get('Customers.ModalHistory.change')}</th>
                  </tr>
                </thead>
                <tbody>
                  {aHistory?.map(oHistory => (
                    <tr key={`customer-history-${oHistory?.custhist_nid}`}>
                      <td style={{ minWidth: 130 }}>
                        {moment
                          .parseZone(oHistory?.custhist_dcreated)
                          .tz(oLocalStorage.get('cTimezone'))
                          .format('DD/MM/YYYY  HH:mm')}
                      </td>
                      <td style={{ minWidth: 150 }}>{oHistory?.custhist_user?.cname}</td>
                      <td style={{ minWidth: 160, textTransform: 'uppercase' }}>{this.cHistoryAction(oHistory)}</td>
                      <td>{this.cHistoryFields(oHistory)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {bLoading && <img className="customer-history-loading" src={imgfLoading} alt="loading" />}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export default ModalHistory;
