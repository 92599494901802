import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import intl from 'react-intl-universal';

import Axios from '../../config/Axios';
import ModalBody from '../default/Page';
import FroalaEditorComponent from '../../config/FroalaEditorComponent';
import AttachmentModal from './AttachmentModal';
import { downloadAttachment } from '../../actions/ActionDocumentDetails';

const DocumentPreview = ({
  oDocument
}) => {
  const [bIsLoading, setIsLoading] = useState(true);
  const [oVersion, setVersion] = useState(null);
  const [aAttachments, setAttachments] = useState([]);
  const [oSelectedAttachment, setSelectedAttachment] = useState(null);
  const [aErrorMsgs, setErrorMsgs] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const oResponse = await Axios.get(`/document/detail/${oDocument.oVersion.ver_nid}/in-progress`);
        setVersion(oResponse.data.oVersion);
        setAttachments(oResponse.data.aAttachments);
      } catch (oError) {
        setErrorMsgs(oError.aMsgErrors);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleAttachmentOpen = (oAttachment) => {
    const cExt = oAttachment.atch_cname.split('.').pop();
    if (/^(png|jpe?g|bmp|pdf|docx?|xlsx?|html|txt|ods)$/.test(cExt)) {
      // imagens, documentos, planilhas etc são visualizados na modal de anexos
      setSelectedAttachment(oAttachment);
    } else {
      // outros arquivos devem ser baixados diretamente
      downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname);
    }
  }

  const handleCloseAttchModal = () => {
    setSelectedAttachment(null);
  }

  if (bIsLoading || (oVersion == null && aErrorMsgs.length > 0)) {
    return <ModalBody loading={bIsLoading ? 1 : 0}>
      <div className="alert error">
        {aErrorMsgs.map((cMsg) => <p>{cMsg}</p>)}
      </div>
    </ModalBody>;
  }

  return (
    <ModalBody loading={0}>
      <FroalaEditorComponent
        bReadOnly={true}
        model={oVersion?.ver_ctext ?? ''}
        fnHandleChange={() => {}}
      />
      <AttachmentModal
        oAttachment={oSelectedAttachment}
        onClose={handleCloseAttchModal}
      />
      {aAttachments.length > 0 && (
        <div className="attachments-container">
          <div className="attachments-wrapper">
            <span>{intl.get('anexos')}:</span>
            <ul className="attachments">
              {aAttachments.map((oAttachment) => (
                <li key={oAttachment.atch_nid}>
                  <span
                    className="download-file-attachment"
                    onClick={(evtClick) => handleAttachmentOpen(oAttachment)}
                  >
                    <img
                      className="align clip-icon"
                      src={require('../../assets/img/clip.svg')}
                      alt="attachment icon"
                    />
                    <span>{oAttachment.atch_cname}</span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </ModalBody>
  );
};

DocumentPreview.propTypes = {
  oDocument: propTypes.object
};

export default DocumentPreview;