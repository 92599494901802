import React, { Component } from 'react';
import { Checkbox as CheckboxCore } from '@stardust-ds/react';

import './styles.scss';

export class Checkbox extends Component {
  constructor(oProps) {
    super(oProps);
  }

  render() {
    const { cLabel, checked, ...oRest } = this.props;
    const activeColor = '#6CB6E3';
    return (
      <label className='custom-checkbox-container' style={checked ? { color: activeColor } : {}}>
        <CheckboxCore checked={checked} activeColor={activeColor} inactiveColor='#737D86' {...oRest} />
        {cLabel}
      </label>
    );
  }
}

export default Checkbox;
