import React from "react";

export default function ClockRewindIcon(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.6667 18.0376L24.5015 15.8718L22.335 18.0376M24.8258 16.4132C24.8258 21.7959 20.4622 26.1594 15.0796 26.1594C9.69687 26.1594 5.33334 21.7959 5.33334 16.4132C5.33334 11.0305 9.69687 6.66699 15.0796 6.66699C18.6552 6.66699 21.7812 8.59256 23.477 11.4633M15.0796 10.9986V16.4132L18.3283 18.579"
        stroke={props?.stroke || '#6CB6E3'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
