import React from 'react';

export default function DownloadIcon(props) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 14.6667V15.7333C20.5 17.2268 20.5 17.9735 20.2094 18.544C19.9537 19.0457 19.5457 19.4537 19.044 19.7094C18.4735 20 17.7268 20 16.2333 20H8.76667C7.27319 20 6.52646 20 5.95603 19.7094C5.45426 19.4537 5.04631 19.0457 4.79065 18.544C4.5 17.9735 4.5 17.2268 4.5 15.7333V14.6667M16.9444 10.2222L12.5 14.6667M12.5 14.6667L8.05556 10.2222M12.5 14.6667V4"
        stroke={props.stroke || '#ffffff'}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
