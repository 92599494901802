"use strict";
import React, { Component, useCallback, useState, useEffect, useMemo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import MaskedInput from 'react-text-mask';
import { Button, Input } from 'reactstrap';
import intl from 'react-intl-universal';
import Select, { createFilter } from 'react-select';
import Axios, { extractErrorMessages } from '../../config/Axios';
import url from '../../config/Url';
import { validarCNPJ, validarCPF } from '../../config/Validator';
import Page from '../default-v2/Page';
import ModalBody from '../default/Page';
import CardHeader from '../default-v2/CardHeader';
import Breadcrumb from '../../components/Breadcrumbs';
import './styles/customer.scss';
import CountriesEn from '../../countries/en.json';
import CountriesPt from '../../countries/pt.json';
import BrazilStates from '../../countries/brazil-states/states.json';
import { can } from '../../config/Permissions';
import imgfConfig from '../default-v2/img/icon-config.svg';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import Applications from './Applications';
import ClockRewindIcon from '../../assets/icons/ClockRewindIcon';
import ModalHistory from './ModalHistory';
import { Form } from "react-bootstrap";
import { Alert } from '../../components/Alert';
import ScrollBar from '../../components/ScrollBar';

const oShow = { display: 'block' };
const oHide = { display: 'none' };
const oMasks = {
  aCpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  aCnpj: [
    /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/
  ],
  aPhone: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  aCep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
};

const aBrazilStates = BrazilStates.map(oState => {
  return { label: oState.initials, value: oState.initials, name: oState.name };
});
const aCountryOptions = (() => {
  const aCountries = oLocalStorage.get('cLanguage') === 'en' ? CountriesEn : CountriesPt;
  return aCountries.map(oCountry => ({
    label: oCountry.name,
    value: oCountry.name
  }));
})();

const getBrazilCities = async (cStateUF) => {
  if (!cStateUF) return [];
  try {
    const oResponse = await Axios.get(`https://cep.acredite.se/cities/${cStateUF}/`);
    return oResponse.data?.map(oCity => ({
      label: oCity.nome,
      value: oCity.nome,
      ibge: oCity.ibge
    })) ?? [];
  } catch (oError) {
    return [];
  }
};

/**
 * Verifica o cep passado, retornando um objeto com os dados do endereço quando encontrado.
 * **Só funciona para ceps brasileiros**
 * @param {string} cZipcode CEP
 */
const checkZipcode = async (cZipcode) => {
  if (!cZipcode || typeof cZipcode !== 'string') return null;
  const cCleanZipcode = cZipcode.replace('-', '');
  if (cCleanZipcode.length !== 8) return null;

  const oResult = {};
  try {
    const oResponse = await Axios.get(`https://cep.acredite.se/cep/${cCleanZipcode}/json`);
    Object.entries(oResponse.data.result).forEach(([cKey, cValue]) => {
      switch (cKey) {
        case 'logradouro':
          return oResult.locl_caddress = cValue;
        case 'localidade':
          return oResult.locl_ccity = cValue;
        case 'siglaEstado':
          return oResult.locl_cstate = cValue;
        case 'bairro':
          return oResult.locl_cdistrict = cValue;
        case 'cibgelocalidade':
          return oResult.locl_cibgecode = cValue;
      }
    });
    if (Object.keys(oResult).length > 0) {
      oResult.locl_ccountry = oLocalStorage.get('cLanguage') === 'en'
        ? 'Brazil'
        : 'Brasil';
    }
    return oResult;
  } catch (oError) {}
  return null;
}

export class Customer extends Component {
  bIsMounted = false;

  constructor(oProps) {
    super(oProps);

    const { oLocation } = oProps;

    this.state = {
      bLoading: true,
      bIsSending: false,
      aAdresses: [],
      oMainAddress: {},
      oAddressEdit: null,
      oCustomer: {
        cPasswordConfirm: ''
      },
      cMessage: oLocation && oLocation.state ? oLocation.state.cMessage : '',
      aPages: [
        {
          cName: 'configs',
          cLink: undefined
        },
        {
          cName: 'Customers.title',
          cLink: undefined
        }
      ],
      bChangedLogo: false,
      bIsOpenModalHistory: false,
      bShowAddressModal: false,
    };
  }

  componentWillUnmount() {
    this.bIsMounted = false;
  }

  inputChangeHandler = evt => {
    const { oCustomer } = this.state;

    if (evt.target.type === 'checkbox') {
      oCustomer[evt.target.name] = evt.target.checked;
      this.setState({
        oCustomer
      });
    } else if (evt.target.value !== null) {
      oCustomer[evt.target.name] = evt.target.value;
      this.setState({
        oCustomer
      });
    }
  };

  mainAddressInputChangeHandler = evt => {
    const { oMainAddress } = this.state;

    if (evt.target.value !== null) {
      oMainAddress[evt.target.name] = evt.target.value;
      this.setState({
        oMainAddress
      });
    }
  };

  submitLocal = async (oAddress, bThrowErrors = false) => {
    const { addAlert } = this.props;
    const oBody = {
      locl_cpostalcode: oAddress.locl_cpostalcode,
      locl_caddress: oAddress.locl_caddress?.trim(),
      locl_cnumber: oAddress.locl_cnumber?.trim(),
      locl_ccomplement: oAddress.locl_ccomplement?.trim(),
      locl_cdistrict: oAddress.locl_cdistrict?.trim(),
      locl_ccity: oAddress.locl_ccity?.trim(),
      locl_cstate: oAddress.locl_cstate?.trim(),
      locl_cfantasyname: oAddress.locl_cfantasyname?.trim(),
      locl_ccnpj: oAddress.locl_ccnpj,
      locl_ccountry: oAddress.locl_ccountry,
      locl_bismain: !!oAddress?.locl_bismain
    };
    try {
      if (oAddress.locl_nid) {
        await Axios.put(`local/${oAddress.locl_nid}`, oBody);
        // evita carregar a lista toda de novo, só atualiza o objeto
        const oResponse = await Axios.get(`local/${oAddress.locl_nid}`);
        const oUpdatedAddress = oResponse.data;
        if (oAddress.locl_bismain) {
          this.setState(({ oMainAddress }) => ({
            oMainAddress: Object.assign(oMainAddress, oUpdatedAddress)
          }));
        } else {
          this.setState(({ aAdresses }) => {
            const oItem = aAdresses.find(oItem => oItem.locl_nid === oAddress.locl_nid);
            Object.assign(oItem, oUpdatedAddress);
            return { aAdresses };
          });
        }
        addAlert('success', intl.get('Address.unidade_atualizada_sucesso'));
      } else {
        await Axios.post('local', oBody);
        await this.getLocals();
        addAlert('success', intl.get('Address.unidade_cadastrada_sucesso'));
      }
    } catch (oError) {
      // deixa a função que chamou lidar com o erro
      if (bThrowErrors) throw oError;
      addAlert('error', oError.aMsgErrors);
    }
  }

  onSubmitCustomer = async (evtSubmit) => {
    evtSubmit.preventDefault();

    const { oMainAddress, oCustomer } = this.state;

    const oAddress = {
      ...oMainAddress,
      locl_cfantasyname: intl.get('matriz'),
      locl_bismain: true,
      locl_ccnpj: oCustomer.cust_ccnpj
    };

    this.updateCustomer();
    this.submitLocal(oAddress);
  };

  updateCustomer = async () => {
    this.setState({
      bIsSending: true
    });
    const { oCustomer, oSelectedFile, oMainAddress, bChangedLogo } = this.state;
    const { addAlert, history } = this.props;

    oCustomer.cust_cpostalcode = oMainAddress.locl_cpostalcode;
    oCustomer.cust_cstreet = oMainAddress.locl_caddress;
    oCustomer.cust_cnumber = oMainAddress.locl_cnumber;
    oCustomer.cust_ccomplement = oMainAddress.locl_ccomplement;
    oCustomer.cust_cdistrict = oMainAddress.locl_cdistrict;
    oCustomer.cust_ccity = oMainAddress.locl_ccity;
    oCustomer.cust_cstate = oMainAddress.locl_cstate;
    oCustomer.cust_ccountry = oMainAddress.locl_ccountry;

    if (oMainAddress.locl_cibgecode == null) {
      oCustomer.cust_cibgecode = oMainAddress.locl_cibgecode;
    }

    if (oCustomer.cust_ccountry === 'Brasil' || oCustomer.cust_ccountry === 'Brazil') {
      const oState = aBrazilStates.find(oStateItem => oStateItem.value === oCustomer.cust_cstate);
      oCustomer.cust_cstate = oState.value;
    }

    const oCustomerUpdating = { ...oCustomer };
    if (!bChangedLogo) {
      delete oCustomerUpdating.cust_clogo;
    }

    try{
      await Axios.put(`/customer/${oCustomer.cust_nid}`, oCustomerUpdating);
      if (oSelectedFile && oSelectedFile !== oCustomer.cust_clogo && bChangedLogo) {
        return await this.uploadImage();
      }
      this.setState({ bIsSending: false });
      addAlert('success', intl.get('Customers.confirmacao_alteracao'));
    } catch(oError) {
      this.setState({ bIsSending: false });
      addAlert('error', oError.aMsgErrors);
    }
  };

  fileChangedHandler = evt => {
    evt.preventDefault();

    const { oCustomer } = this.state;
    const frChanged = new FileReader();
    const fFile = evt.target.files[0];

    frChanged.onloadend = () => {
      oCustomer.cust_clogo = fFile.name;
      if (fFile.type === 'image/png' || fFile.type === 'image/jpg' || fFile.type === 'image/jpeg') {
        this.setState({
          oSelectedFile: fFile,
          cImagePreviewUrl: frChanged.result,
          oCustomer,
          bChangedLogo: true
        });
      } else {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              danger
              confirmBtnText="Ok"
              confirmBtnBsStyle="warning"
              title={intl.get('Customers.logo')}
              onConfirm={this.hideAlert}
              onCancel={this.hideAlert}
            >
              {intl.get('Customers.image_extension_message')}
            </SweetAlert>
          )
        });
      }
    };
    if (fFile) frChanged.readAsDataURL(fFile);
  };

  triggerInputFile = () => {
    const elLink = document.getElementById('cust_clogo');
    elLink.click();
  };

  removeImage = () => {
    const { oCustomer } = this.state;
    oCustomer.cust_clogo = '';

    this.setState({
      oSelectedFile: '',
      cImagePreviewUrl: '',
      oCustomer
    });
  };

  getLocals = async () => {
    const oResponse = await Axios.get('/local');

    const allAddresses = await Promise.all(
      oResponse.data.map(async (aLocal, indexKey) => ({
        nKey: indexKey,
        locl_ccity: aLocal.locl_ccity,
        locl_nid: aLocal.locl_nid,
        locl_cfantasyname: aLocal.locl_cfantasyname,
        locl_ccnpj: aLocal.locl_ccnpj,
        locl_cpostalcode: aLocal.locl_cpostalcode,
        locl_caddress: aLocal.locl_caddress,
        locl_cnumber: aLocal.locl_cnumber,
        locl_ccomplement: aLocal.locl_ccomplement,
        locl_cdistrict: aLocal.locl_cdistrict,
        locl_cstate: aLocal.locl_cstate,
        locl_ccountry: aLocal.locl_ccountry,
        locl_bismain: !!aLocal?.locl_bismain,
        bHasEquipments: !!aLocal?.bHasEquipments,
        cust_nid: aLocal.cust_nid
      }))
    );

    let oMainAddress = allAddresses.find(address => address.locl_bismain);
    if (!oMainAddress) {
      const { oCustomer } = this.state;
      oMainAddress = {
        nKey: 0,
        aBrazilCities: [],
        locl_ccity: oCustomer.cust_ccity ?? '',
        locl_nid: null,
        locl_cfantasyname: oCustomer.cust_cfantasyname ?? null,
        locl_ccnpj: oCustomer.cust_ccnpj ?? null,
        locl_cpostalcode: oCustomer.cust_cpostalcode ?? '',
        locl_caddress: oCustomer.cust_ccity ?? '',
        locl_cnumber: oCustomer.cust_cnumber ?? '',
        locl_ccomplement: oCustomer.cust_ccomplement ?? null,
        locl_cdistrict: oCustomer.cust_cdistrict ?? '',
        locl_cstate: oCustomer.cust_cstate ?? '',
        locl_ccountry: oCustomer.cust_ccountry ?? '',
        locl_bismain: true
      };
    }

    const otherAddresses = allAddresses.filter(address => !address.locl_bismain);

    this.setState({
      aAdresses: otherAddresses,
      oMainAddress
    });
  };

  componentDidMount = () => {
    this.bIsMounted = true;
    let cImagePreviewUrl = '';
    const { setAlert, history } = this.props;
    const { cMessage } = this.state;

    if (!can('admin')) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    setAlert('info', cMessage);
    this.getLocals();
    Axios.get('/customer/form/edit')
      .then(response => {
        if (this.bIsMounted) {
          const oResData = response.data;
          let cApiKey = ''; // Valor padrão para a chave API
          let nRespApi = ''; // Valor padrão de respostas
          let nQtdToken = '';

          // Tentar decodificar cust_ckeyapigpt e extrair cApiKey
          if (oResData.cust_ckeyapigpt) {
            try {
              const custCkeyapigptObj = JSON.parse(oResData.cust_ckeyapigpt);
              cApiKey = custCkeyapigptObj.cApiKey || '';
              nRespApi = custCkeyapigptObj.n || '';
              nQtdToken = custCkeyapigptObj.max_tokens || '';
            } catch (e) {
              console.error('Erro ao decodificar cust_ckeyapigpt:', e);
            }
          }

          this.setState({
            bLoading: false,
            oCustomer: {
              ...oResData,
              cApiKey, // Armazena apenas a chave API
              nRespApi,
              nQtdToken
            },
            oSelectedFile: oResData.cust_clogo,
            cImagePreviewUrl: oResData.cust_clogo || ''
          });
        }
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError?.response?.data?.message || 'Erro ao carregar dados do cliente.');
      });

  };

  clearIBGECode = () => {
    const { oMainAddress } = this.state;
    oMainAddress.locl_cibgecode = '';
    this.setState({ oMainAddress });
  };

  fireInvalidCNPJAlert = () => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          danger
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title={intl.get('Customers.cnpj_invalido')}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
        />
      )
    });
  };

  validateCNPJCust = evt => {
    if (evt.target.value === '' || validarCNPJ(evt.target.value)) return;
    const { oCustomer } = this.state;
    oCustomer.cust_ccnpj = null;
    this.setState({ oCustomer });
    this.fireInvalidCNPJAlert();
  };

  validateCNPJLocal = (evt, nKey) => {
    if (evt.target.value === '' || validarCNPJ(evt.target.value)) return;
    const { aAdresses } = this.state;
    const nAddressIndex = aAdresses.findIndex(oFindingAddress => oFindingAddress.nKey === nKey);
    aAdresses[nAddressIndex].locl_ccnpj = null;
    this.setState({ aAdresses });
    this.fireInvalidCNPJAlert();
  };

  validateCPF = evt => {
    if (evt.target.value !== '' && !validarCPF(evt.target.value)) {
      const { oCustomer } = this.state;
      oCustomer.cust_ccpf = '';
      this.setState({
        oCustomer,
        rcmpAlert: (
          <SweetAlert
            danger
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title={intl.get('Customers.cpf_invalido')}
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
          />
        )
      });
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  uploadImage() {
    const { oSelectedFile, oCustomer } = this.state;
    const { setAlert } = this.props;

    const fdataImage = new FormData();
    fdataImage.append('cust_clogo', oSelectedFile, oSelectedFile.name);
    fdataImage.append('cust_nid', oCustomer.cust_nid);

    Axios.post('/customer/logo', fdataImage, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        setAlert('success', intl.get('Customers.confirmacao_alteracao'));
        this.setState({
          bIsSending: false
        });
      })
      .catch(oError => {
        setAlert(
          'error',
          intl.get('Customers.confirmacao_alteracao') +
            intl.get('Customers.confirmacao_alteracao_entretanto', { cMsg: extractErrorMessages(oError) })
        );
        this.setState({
          bIsSending: false
        });
      });
  }

  handleChange = (evt, nKey) => {
    const { aAdresses } = this.state;

    aAdresses.forEach(oAddress => {
      if (oAddress.nKey === nKey) {
        // eslint-disable-next-line no-param-reassign
        oAddress[evt.target.name] = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
      }
    });
    this.setState({ aAdresses });
  };

  deleteLocal = async (oAddress) => {
    const { addAlert } = this.props;
    this.hideAlert();
    try {
      await Axios.delete(`local/${oAddress.locl_nid}`)
      this.setState(oCurrentState => ({
        aAdresses: oCurrentState.aAdresses.filter(oItem => oItem.nKey !== oAddress.nKey)
      }));
      addAlert('success', intl.get('Address.unidade_removida_sucesso'));
    } catch (oError) {
      addAlert('error', oError.aMsgErrors);
    }
  };

  removeLocale = oAddress => {
    if (!oAddress.locl_nid) return;

    this.setState({
      rcmpAlert: (
      <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          title={intl.get('Address.remover_unidade')}
          onConfirm={() => this.deleteLocal(oAddress)}
          confirmBtnBsStyle="danger"
          onCancel={this.hideAlert}
          showConfirm={!oAddress.bHasEquipments}
        >
          <div className="docusign-modal-text align-center">
            {oAddress.bHasEquipments
              ? (
                <span style={{ fontWeight: '700' }}>
                  {intl.get('Address.unidade_remover_aviso_equipamento')}
                </span>
              ) : intl.get('Address.unidade_remover_confirmar', { cName: oAddress.locl_cfantasyname })}
          </div>
        </SweetAlert>
      )
    });
  };

  generateCardHeader = () => {
    const { history } = this.props;
    const rcmpHistoryButton = (
      <div className='customer-header-custom-buttons-container'>
        <button onClick={() => this.setState({ bIsOpenModalHistory: true })} title={intl.get('Customers.ModalHistory.title')}>
          <ClockRewindIcon />
        </button>
      </div>
    )

    const rcmpCardHeader = <CardHeader history={history} cHtml={rcmpHistoryButton} />;
    return rcmpCardHeader;
  };

  handleChangeCustomer = async (aSelectedOption, cField) => {
    const { oMainAddress } = this.state;

    oMainAddress[cField] = aSelectedOption.value;

    if (
      cField === 'locl_ccountry' &&
      (oMainAddress.locl_ccountry !== 'Brasil' || oMainAddress.locl_ccountry !== 'Brazil')
    ) {
      oMainAddress.locl_cstate = '';
      oMainAddress.locl_ccity = '';
    } else if (cField === 'locl_cstate') {
      oMainAddress.aBrazilCities = await getBrazilCities(oMainAddress[cField]);
      oMainAddress.locl_ccity = '';
      oMainAddress.locl_cibgecode = null;
    } else if (cField === 'locl_ccity') {
      oMainAddress.locl_cibgecode = aSelectedOption.ibje;
    }

    this.setState({
      oMainAddress
    });
  };

  hideAddressModal = () => {
    this.setState({
      bShowAddressModal: false,
      oAddressEdit: null,
    });
  }

  handleOnCloseModalHistory = () => {
    this.setState({ bIsOpenModalHistory: false });
  }

  render() {
    const {
      bLoading,
      bIsSending,
      rcmpAlert,
      oSelectedFile,
      oCustomer,
      cImagePreviewUrl,
      aAdresses,
      aPages,
      oMainAddress,
      bIsOpenModalHistory,
      oAddressEdit,
      bShowAddressModal,
    } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('Customers.title');

    const bIsPFPJ = oCustomer.cust_ctype === 'PF';

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={imgfConfig}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-customer"
        cCurrentSideMenuOption={intl.get('configs')}
      >
        {rcmpAlert}
        {getAlert()}

        {bShowAddressModal && (
          <SweetAlert
            customClass="modal-edit lg disabled-overflow ScrollBar customer-local"
            title=""
            onConfirm={() => {}}
            showConfirm={false}
          >
            <div className="head-modal">
              {intl.get(oAddressEdit ? 'Address.editar_unidade' : 'Address.nova_unidade')}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAddressModal}
                onClick={this.hideAddressModal}
                className="close"
              />
            </div>
            <ScrollBar>
              <AddressModal
                oAddressEdit={oAddressEdit}
                fnOnSubmit={async oAddress => {
                  await this.submitLocal(oAddress, true);
                  this.hideAddressModal();
                }}
                fnOnHide={this.hideAddressModal}
              />
            </ScrollBar>
          </SweetAlert>
        )}

        <form className="customer-form" onSubmit={this.onSubmitCustomer}>
          <fieldset>
            <div className="customer-head">
              <legend>{intl.get('Customers.legenda_informacoes')}</legend>
            </div>

            <div className="customer-info">
              <div className="avatar">
                <div>
                  <Input
                    onChange={this.fileChangedHandler}
                    type="file"
                    name="cust_clogo"
                    id="cust_clogo"
                    className="hidden avatar-upload"
                    data-preview="avatar-placeholder img"
                    accept=".jpg, .jpeg, .png"
                    hidden
                  />

                  <span
                    href="customer"
                    className={
                      oSelectedFile || oCustomer.cust_clogo
                        ? 'avatar-placeholder disabled-avatar'
                        : 'avatar-placeholder'
                    }
                    data-field="[name=cust_clogo]"
                  >
                    {intl.get('Customers.insert_log')}
                    <img
                      style={{ visibility: cImagePreviewUrl ? 'visible' : 'hidden' }}
                      src={cImagePreviewUrl}
                      alt=""
                      id="avatar-preview"
                    />
                  </span>

                  <span
                    className="icon"
                    role="button"
                    tabIndex="0"
                    onKeyDown={this.handleKeyDown}
                    onClick={this.triggerInputFile}
                    href="customer"
                    data-field="[name=cust_clogo]"
                  >
                    <i className="icon-camera" />
                  </span>
                </div>
              </div>

              <div className="customer-fields">
                <div className="customer-row">
                  <label className="colspan-1-half">
                    {intl.get('Customers.tipo_cadastro')} <span className="required">*</span>
                    <Input
                      disabled={oCustomer.cust_cadminid}
                      type="select"
                      name="cust_ctype"
                      id="cust_ctype"
                      onChange={evt => this.inputChangeHandler.call(this, evt)}
                      value={oCustomer.cust_ctype}
                      required
                    >
                      <option value="PF">{intl.get('Customers.pessoa_fisica')}</option>
                      <option value="PJ">{intl.get('Customers.pessoa_juridica')}</option>
                    </Input>
                  </label>

                  <label className="colspan-4">
                    {bIsPFPJ ? intl.get('Customers.cnome') : intl.get('Customers.razao_social')}{' '}
                    <span className="required">*</span>
                    <Input
                      type="text"
                      name="cust_cname"
                      id="cust_cname"
                      maxLength="250"
                      required
                      onChange={evt => this.inputChangeHandler.call(this, evt)}
                      value={oCustomer.cust_cname || ''}
                      placeholder={intl.get('Customers.placeholder_social_reason')}
                    />
                  </label>
                </div>

                <label style={bIsPFPJ ? oShow : oHide} className="colspan-1-half">
                  {intl.get('Customers.ccpf')} <span className="required">*</span>
                  <MaskedInput
                    onBlur={this.validateCPF}
                    mask={oMasks.aCpf}
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    render={(cRef, oProps) => (
                      // para o "...props", tem que analisar quais atributos estão sendo necessários
                      <Input
                        disabled={oCustomer.cust_cadminid}
                        innerRef={cRef}
                        {...oProps}
                        value={oCustomer.cust_ccpf || ''}
                        type="text"
                        name="cust_ccpf"
                        id="cust_ccpf"
                        className="mascara-cpf"
                        required={bIsPFPJ}
                        placeholder={intl.get('Customers.placeholder_CPF')}
                      />
                    )}
                  />
                </label>

                <label style={!bIsPFPJ ? oShow : oHide} className="colspan-1-half">
                  {intl.get('Customers.ccnpj')} <span className="required">*</span>
                  <MaskedInput
                    onBlur={this.validateCNPJCust}
                    mask={oMasks.aCnpj}
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    render={(cRef, oProps) => (
                      <Input
                        disabled={oCustomer.cust_cadminid}
                        innerRef={cRef}
                        // para o "...props", tem que analisar quais atributos estão sendo necessários
                        {...oProps}
                        value={oCustomer.cust_ccnpj ?? ''}
                        type="text"
                        name="cust_ccnpj"
                        id="cust_ccnpj"
                        placeholder={intl.get('Customers.placeholder_CNPJ')}
                        required={!bIsPFPJ}
                      />
                    )}
                  />
                </label>

                <label style={!bIsPFPJ ? oShow : oHide} className="colspan-4">
                  <span>{intl.get('Customers.cnomefantasia')}</span>
                  <Input
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    value={oCustomer.cust_cfantasyname ?? ''}
                    type="text"
                    name="cust_cfantasyname"
                    id="cust_cfantasyname"
                    placeholder={intl.get('Customers.cnomefantasia')}
                  />
                </label>

                <label className={!bIsPFPJ ? 'colspan-1-half' : 'colspan-1-third'}>
                  {intl.get('Customers.ctelefone')}
                  <MaskedInput
                    mask={oMasks.aPhone}
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    render={(cRef, oProps) => (
                      <Input
                        innerRef={cRef}
                        // para o "...props", tem que analisar quais atributos estão sendo necessários
                        {...oProps}
                        value={oCustomer.cust_cphone || ''}
                        type="text"
                        name="cust_cphone"
                        id="cust_cphone"
                        className="mascara-telefone"
                        placeholder={intl.get('Customers.placeholder_phone')}
                      />
                    )}
                  />
                </label>

                <label className="colspan-1-third">
                  {intl.get('Customers.ccelular')} <span className="required">*</span>
                  <MaskedInput
                    mask={oMasks.aPhone}
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    render={(cRef, oProps) => (
                      <Input
                        innerRef={cRef}
                        // para o "...props", tem que analisar quais atributos estão sendo necessários
                        {...oProps}
                        value={oCustomer.cust_ccellphone || ''}
                        type="text"
                        name="cust_ccellphone"
                        id="cust_ccellphone"
                        className="mascara-telefone"
                        placeholder={intl.get('Customers.placeholder_phone')}
                        required
                      />
                    )}
                  />
                </label>

                {!bIsPFPJ && (
                  <label className="">
                    {intl.get('Customers.eenquadramentofiscal')}
                    <Input
                      type="select"
                      name="cust_etaxframework"
                      id="cust_etaxframework"
                      required
                      onChange={evt => this.inputChangeHandler.call(this, evt)}
                      value={oCustomer.cust_etaxframework || ''}
                    >
                      <option value="">{intl.get('select')}</option>
                      <option value="Optante">{intl.get('Customers.ef_optante')}</option>
                      <option value="Não optante">{intl.get('Customers.ef_nao_optante')}</option>
                      <option value="Isento">{intl.get('Customers.isento')}</option>
                    </Input>
                  </label>
                )}
              </div>
            </div>
          </fieldset>

          <fieldset>
            <div className="customer-head">
              <legend>APIs</legend>
            </div>

            <div className="customer-info">
              <div className="customer-row">
                <label className="colspan-1-quarter">
                  {intl.get('ChatGpt.chave_api')}
                  <Input
                    value={oCustomer.cApiKey || ''}
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    type="text"
                    name="cApiKey"
                    id="cApiKey"
                    className="api-key-input"
                    placeholder={intl.get('ChatGpt.digitar_chave')}
                  />
                </label>
                <label className="colspan-1-half">
                  {intl.get('ChatGpt.numero_resp')}
                  <Input
                    value={oCustomer.nRespApi || ''}
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    type="text"
                    name="nRespApi"
                    id="nRespApi"
                    className="api-key-input"
                    placeholder={intl.get('ChatGpt.digitar_resposta')}
                  />
                </label>
                <label className="colspan-1-half">
                  {intl.get('ChatGpt.numero_tokens')}
                  <Input
                    value={oCustomer.nQtdToken || ''}
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                    type="text"
                    name="nQtdToken"
                    id="nQtdToken"
                    className="api-key-input"
                    placeholder={intl.get('ChatGpt.digitar_tokens')}
                  />
                </label>
              </div>
            </div>
          </fieldset>

          <Applications {...this.props} />

          <fieldset>
            <div className="customer-head">
              <legend>{intl.get('Address.legenda_endereco')}</legend>
            </div>

            <div className="customer-address customer-address-fix">
              <label className="search colspan-1-half">
                {intl.get('Address.ccep')} <span className="required">*</span>
                <MaskedInput
                  mask={oMasks.aCep}
                  onChange={evt => {
                    this.clearIBGECode();
                    this.mainAddressInputChangeHandler.call(this, evt);
                  }}
                  render={(cRef, oProps) => (
                    <Input
                      type="text"
                      name="locl_cpostalcode"
                      id="locl_cpostalcode"
                      className="mascara-cep cep-preenche"
                      placeholder={intl.get('Customers.placeholder_CEP')}
                      innerRef={cRef}
                      // para o "...props", tem que analisar quais atributos estão sendo necessários
                      {...oProps}
                      // eslint-disable-next-line no-nested-ternary
                      value={oMainAddress.locl_cpostalcode ?? ''}
                      required
                    />
                  )}
                />
                <span
                  role="button"
                  tabIndex="0"
                  aria-labelledby="split2"
                  onKeyDown={this.handleKeyDown}
                  title={intl.get('Address.busca_cep')}
                  className="btn-hover"
                  onClick={async () => {
                    const oAddressData = (await checkZipcode(oMainAddress.locl_cpostalcode)) ?? {};
                    if (Object.keys(oAddressData).length === 0) return;
                    this.setState(({ oMainAddress }) => ({
                      oMainAddress: Object.assign(oMainAddress, oAddressData)
                    }));
                  }}
                />
              </label>

              <label className="colspan-1-quarter">
                {intl.get('Address.clogradouro')} <span className="required">*</span>
                <Input
                  type="text"
                  name="locl_caddress"
                  id="locl_caddress"
                  maxLength="250"
                  onChange={evt => this.mainAddressInputChangeHandler.call(this, evt)}
                  value={oMainAddress.locl_caddress ? `${oMainAddress.locl_caddress}` : ''}
                  placeholder={intl.get('Customers.placeholder_address')}
                  required
                />
              </label>

              <label className="colspan-1-half">
                {intl.get('Address.cnumero')} <span className="required">*</span>
                <Input
                  type="text"
                  name="locl_cnumber"
                  id="locl_cnumber"
                  maxLength="250"
                  onChange={evt => this.mainAddressInputChangeHandler.call(this, evt)}
                  value={oMainAddress.locl_cnumber ?? ''}
                  placeholder={intl.get('Customers.placeholder_number')}
                  required
                />
              </label>

              <label className="colspan-1-half">
                {intl.get('Address.ccomplemento')}
                <Input
                  type="text"
                  name="locl_ccomplement"
                  id="locl_ccomplement"
                  maxLength="250"
                  onChange={evt => this.mainAddressInputChangeHandler.call(this, evt)}
                  value={oMainAddress.locl_ccomplement ?? ''}
                />
              </label>

              <label className="colspan-1-20p">
                {intl.get('Address.cbairro')} <span className="required">*</span>
                <Input
                  type="text"
                  name="locl_cdistrict"
                  id="locl_cdistrict"
                  maxLength="250"
                  onChange={evt => this.mainAddressInputChangeHandler.call(this, evt)}
                  value={oMainAddress.locl_cdistrict ?? ''}
                  placeholder={intl.get('Customers.placeholder_neighborhood')}
                  required
                />
              </label>

              <label className="colspan-1-20p">
                {intl.get('Address.cpais')} <span className="required">*</span>
                <Select
                  required
                  className="selectCustom"
                  classNamePrefix="react-select"
                  options={aCountryOptions}
                  noOptionsMessage={() => intl.get('sem_opcoes')}
                  onChange={elSelectedOption => this.handleChangeCustomer(elSelectedOption, 'locl_ccountry')}
                  value={
                    oMainAddress.locl_ccountry
                      ? { label: oMainAddress.locl_ccountry, value: oMainAddress.locl_ccountry }
                      : ''
                  }
                  placeholder={intl.get('Address.cpais')}
                />
              </label>

              <label className="colspan-1-10p">
                {intl.get('Address.cestado')} <span className="required">*</span>
                {oMainAddress.locl_ccountry === 'Brasil' || oMainAddress.locl_ccountry === 'Brazil' ? (
                  <Select
                    required
                    className="selectCustom"
                    classNamePrefix="react-select"
                    options={aBrazilStates}
                    noOptionsMessage={() => intl.get('sem_opcoes')}
                    onChange={elSelectedOption => this.handleChangeCustomer(elSelectedOption, 'locl_cstate')}
                    value={
                      oMainAddress.locl_cstate
                        ? { label: oMainAddress.locl_cstate, value: oMainAddress.locl_cstate }
                        : ''
                    }
                    placeholder={intl.get('Address.cestado')}
                  />
                ) : (
                  <>
                    <Input
                      className="selectCustom"
                      type="text"
                      name="locl_cstate"
                      id="locl_cstate"
                      maxLength="250"
                      onChange={evt => this.mainAddressInputChangeHandler.call(this, evt)}
                      value={oMainAddress.locl_cstate ?? ''}
                      required
                    />
                  </>
                )}
              </label>

              <label className="colspan-1-15p">
                {intl.get('Address.ccidade')} <span className="required">*</span>
                {oMainAddress.locl_ccountry === 'Brasil' || oMainAddress.locl_ccountry === 'Brazil' ? (
                  <Select
                    required
                    className="selectCustom"
                    classNamePrefix="react-select"
                    filterOption={createFilter({ matchFrom: 'start' })}
                    options={oMainAddress.aBrazilCities}
                    noOptionsMessage={() => intl.get('sem_opcoes')}
                    onChange={elSelectedOption => this.handleChangeCustomer(elSelectedOption, 'locl_ccity')}
                    value={
                      oMainAddress.locl_ccity ? { label: oMainAddress.locl_ccity, value: oMainAddress.locl_ccity } : ''
                    }
                    placeholder={intl.get('Address.ccidade')}
                  />
                ) : (
                  <Input
                    className="selectCustom"
                    type="text"
                    name="locl_ccity"
                    id="locl_ccity"
                    maxLength="250"
                    onChange={evt => {
                      this.clearIBGECode();
                      this.mainAddressInputChangeHandler.call(this, evt);
                    }}
                    value={oMainAddress.locl_ccity ?? ''}
                    required
                  />
                )}
              </label>
            </div>
          </fieldset>

          <AddressList
            aAddresses={aAdresses}
            fnOnEdit={oAddress => {
              this.setState({
                oAddressEdit: oAddress,
                bShowAddressModal: true
              });
            }}
            fnOnDelete={this.removeLocale}
          />

          <div className="button-stack">
            <Button
              type="button"
              className="btn btn-cancel"
              onClick={() => this.componentDidMount()}
              disabled={bIsSending}
            >
              {intl.get('cancel')}
            </Button>
            <Button
              type="button"
              onClick={() => {
                this.setState({
                  bShowAddressModal: true,
                  oAddressEdit: null
                });
              }}
              // disabled={bIsSending}
              className="btn-add"
            >
              {intl.get('Address.add_nova_unidade')}
            </Button>
            <Button type="submit" disabled={bIsSending}>
              {intl.get('salvar')}
            </Button>
          </div>
        </form>
        <ModalHistory bIsOpen={bIsOpenModalHistory} onClose={this.handleOnCloseModalHistory} />
      </Page>
    );
  }
}


const AddressList = ({
  aAddresses = [],
  fnOnEdit = () => {},
  fnOnDelete = () => {},
}) => {
  if (aAddresses.length === 0) {
    return null;
  }
  return (
    <fieldset>
      <div className="customer-head">
        <legend>{intl.get("Address.unidade")}</legend>
      </div>
      <table className="table-group table" style={{marginTop: "50px"}}>
        <thead>
          <tr>
            <th>{intl.get('Customers.cnomefantasia')}</th>
            <th>{intl.get('Customers.ccnpj')}</th>
            <th>{intl.get('Address.cestado')}</th>
            <th>{intl.get('Address.ccidade')}</th>
            <th>{/* Ações */}</th>
          </tr>
        </thead>
        <tbody>
          {aAddresses.map((oAddress) => (
            <tr key={oAddress.locl_nid}>
              <td>{oAddress.locl_cfantasyname}</td>
              <td>{oAddress.locl_ccnpj}</td>
              <td>{oAddress.locl_cstate}</td>
              <td>{oAddress.locl_ccity}</td>
              <td>
                <div className="buttons">
                  <button
                    type="button"
                    title={intl.get('Address.editar_unidade')}
                    className="edit"
                    onClick={() => fnOnEdit(oAddress)}
                  ></button>
                  <button
                    type="button"
                    title={intl.get('Address.remover_unidade')}
                    className="remove"
                    onClick={() => fnOnDelete(oAddress)}
                  ></button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </fieldset>
  );
};



const oEmptyAddress = {
  locl_cpostalcode: '',
  locl_caddress: '',
  locl_cnumber: '',
  locl_ccomplement: '',
  locl_cdistrict: '',
  locl_ccity: '',
  locl_cstate: '',
  locl_cfantasyname: '',
  locl_ccnpj: '',
  locl_ccountry: '',
}

const AddressModal = ({
  fnOnHide = () => {},
  fnOnSubmit = async () => {},
  oAddressEdit = null,
}) => {
  const [bIsSubmitting, setIsSubmitting] = useState(false);
  const [oAddress, setAddress] = useState({ ...oEmptyAddress });
  const [aBrazilCities, setBrazilCities] = useState([]);
  const [aErrors, setErrors] = useState([]);

  useEffect(() => {
    if (oAddressEdit) {
      getBrazilCities(oAddressEdit.locl_cstate)
        .then((aCities) => setBrazilCities(aCities));
      setAddress({ ...oAddressEdit });
    } else {
      setAddress({ ...oEmptyAddress });
    }
  }, []);

  const cZipcode = oAddress.locl_cpostalcode;
  useEffect(() => {
    if (/[0-9]$/.test(cZipcode)) {
      handleZipcodeSearch();
    }
  }, [cZipcode]);

  const handleHide = useCallback((evtClick) => {
    fnOnHide(evtClick);
  }, [fnOnHide]);

  const handleSubmit = async (evtSubmit) => {
    evtSubmit.preventDefault();
    if (bIsSubmitting) return;

    const aErrorsFound = [];
    if (oAddress.locl_ccnpj && !validarCNPJ(oAddress.locl_ccnpj)) {
      aErrorsFound.push(intl.get('Customers.cnpj_invalido'));
    }
    if (!oAddress.locl_ccountry) {
      aErrorsFound.push(intl.get('Address.validacao.pais_obrigatorio'))
    }
    if (!oAddress.locl_cstate) {
      aErrorsFound.push(intl.get('Address.validacao.estado_obrigatorio'))
    }
    if (!oAddress.locl_ccity) {
      aErrorsFound.push(intl.get('Address.validacao.cidade_obrigatorio'))
    }
    if (aErrorsFound.length || !evtSubmit.target.checkValidity()) {
      setErrors(aErrorsFound);
      return evtSubmit.stopPropagation();
    }

    setIsSubmitting(true);
    try {
      await fnOnSubmit(oAddress, true);
      // setIsSubmitting(false);
    } catch (oErrors) {
      setErrors(oErrors.aMsgErrors);
      setIsSubmitting(false);
    }
  };

  const handleZipcodeSearch = async () => {
    const oAddressData = await checkZipcode(oAddress.locl_cpostalcode) ?? {};
    if (Object.keys(oAddressData).length === 0) return;
    setBrazilCities(await getBrazilCities(oAddressData.locl_cstate));
    setAddress((oPrevAddress) => ({ ...oPrevAddress, ...oAddressData }));
  };

  const handleAddressChange =(evtChange) => {
    const cName = evtChange.target.name;
    const cValue = evtChange.target.value;
    setAddress((oPrevAddress) => ({ ...oPrevAddress, [cName]: cValue }));
  };

  return (
    <ModalBody
      loading={0}
      className="customer-local"
    >
      {!!aErrors.length && (
        <Alert type="error" onCloseAlert={() => setErrors([])}>
          {aErrors.map((cMsg) => <p>{cMsg}</p>)}
        </Alert>
      )}
      <Form
        onSubmit={handleSubmit}
        // style={{fontSize: "16px", width: "97%", marginTop: "10px"}}
        className="form"
      >
        <div className="fields">
          <Form.Group controlId="locl_cfantasyname" className="col-fantasyname">
            <Form.Label
              style={{margin: "0 0 2px 0"}}
            >
              {intl.get('Customers.cnomefantasia')}
              <span className="required"> *</span>
            </Form.Label>
            <Form.Control
              name="locl_cfantasyname"
              type="text"
              value={oAddress.locl_cfantasyname ?? ''}
              onChange={handleAddressChange}
              maxLength={250}
              placeholder={intl.get('Customers.cnomefantasia')}
              required
            />
          </Form.Group>

          <Form.Group controlId="locl_ccnpj" className="col-cnpj">
            <Form.Label style={{margin: "0 0 2px 0"}}>
              {intl.get('Customers.ccnpj')}
            </Form.Label>
            <MaskedInput
              name="locl_ccnpj"
              onChange={handleAddressChange}
              value={oAddress.locl_ccnpj ?? ''}
              mask={oMasks.aCnpj}
              className="form-control"
              type="text"
              placeholder={intl.get('Customers.placeholder_CNPJ')}
            />
          </Form.Group>

          <Form.Group controlId="locl_cpostalcode" className="search col-cep">
            <Form.Label style={{margin: "0 0 2px 0"}}>
              {intl.get('Address.ccep')}
              <span className="required"> *</span>
            </Form.Label>
            <MaskedInput
              name="locl_cpostalcode"
              value={oAddress.locl_cpostalcode ?? ''}
              onChange={handleAddressChange}
              mask={oMasks.aCep}

              className="form-control"
              type="text"
              placeholder={intl.get('Address.ccep')}
            />
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="split2"
              title={intl.get('Address.busca_cep')}
              className="btn-hover"
              onKeyDown={handleZipcodeSearch}
              onClick={handleZipcodeSearch}
            />
          </Form.Group>

          <Form.Group controlId="locl_caddress" className="col-address">
            <Form.Label style={{margin: "0 0 2px 0"}}>
              {intl.get('Address.clogradouro')}
              <span className="required"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="locl_caddress"
              value={oAddress.locl_caddress ?? ''}
              onChange={handleAddressChange}
              maxLength={250}
              placeholder={intl.get('Customers.placeholder_address')}
              required
            />
          </Form.Group>

          <Form.Group controlId="locl_cnumber" className="col-number">
            <Form.Label style={{margin: "0 0 2px 0"}}>
              {intl.get('Address.cnumero')}
              <span className="required"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="locl_cnumber"
              value={oAddress.locl_cnumber ?? ''}
              onChange={handleAddressChange}
              maxLength={250}
              placeholder={intl.get('Customers.placeholder_number')}
              required
            />
          </Form.Group>

          <Form.Group controlId="locl_ccomplement" className="col-complement">
            <Form.Label style={{margin: "0 0 2px 0"}}>
              {intl.get('Address.ccomplemento')}
            </Form.Label>
            <Form.Control
              type="text"
              name="locl_ccomplement"
              value={oAddress.locl_ccomplement ?? ''}
              onChange={handleAddressChange}
              maxLength={250}
            />
          </Form.Group>

          <Form.Group controlId="locl_cdistrict" className="col-district">
            <Form.Label style={{margin: "0 0 2px 0"}}>
              {intl.get('Address.cbairro')}
              <span className="required"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="locl_cdistrict"
              value={oAddress.locl_cdistrict ?? ''}
              onChange={handleAddressChange}
              maxLength={250}
              placeholder={intl.get('Customers.placeholder_neighborhood')}
              required
            />
          </Form.Group>

          <Form.Group controlId="locl_ccountry" className="col-country">
            <Form.Label style={{ marginBottom: "2px" }}>
              {intl.get('Address.cpais')}
              <span className="required"> *</span>
            </Form.Label>
            <Select
              name="locl_ccountry"
              className="selectCustom"
              classNamePrefix="react-select"
              // string vazia para renderizar o placeholder
              value={oAddress.locl_ccountry ? {
                label: oAddress.locl_ccountry,
                value: oAddress.locl_ccountry
              } : ''}
              onChange={(oSelectedOption) => {
                setAddress((oPrevAddress) => ({
                  ...oPrevAddress,
                  locl_ccountry: oSelectedOption.value,
                  locl_cstate: '',
                  locl_ccity: '',
                  locl_cibgecode: '',
                }));
              }}
              options={aCountryOptions}
              placeholder={intl.get('Address.cpais')}
              noOptionsMessage={() => intl.get('sem_opcoes')}
              required
            />
          </Form.Group>

          <Form.Group controlId="locl_cstate" className="col-state">
            <Form.Label style={{ marginBottom: "2px" }}>
              {intl.get('Address.cestado')}
              <span className="required"> *</span>
            </Form.Label>
            {/^Bra[sz]il$/.test(oAddress.locl_ccountry) ? (
              <Select
                name="locl_cstate"
                className="selectCustom"
                classNamePrefix="react-select"
                // string vazia para renderizar o placeholder
                value={oAddress.locl_cstate ? {
                  label: oAddress.locl_cstate,
                  value: oAddress.locl_cstate
                } : ''}
                onChange={async (oSelectedOption) => {
                  setAddress((oPrevAddress) => ({
                    ...oPrevAddress,
                    locl_ccity: '',
                    locl_cibgecode: '',
                    locl_cstate: oSelectedOption.value,
                  }));
                  const aCities = await getBrazilCities(oSelectedOption.value);
                  setBrazilCities(aCities);
                }}
                options={aBrazilStates}
                placeholder={intl.get('Address.cestado')}
                noOptionsMessage={() => intl.get('sem_opcoes')}
                required
              />
            ) : (
              <Form.Control
                type="text"
                name="locl_cstate"
                value={oAddress.locl_cstate ?? ''}
                onChange={handleAddressChange}
                maxLength={250}
                placeholder={intl.get('Address.cestado')}
                required
              />
            )}
          </Form.Group>

          <Form.Group controlId="locl_ccity" className="col-city">
            <Form.Label style={{ marginBottom: "2px" }}>
              {intl.get('Address.ccidade')}
              <span className="required"> *</span>
            </Form.Label>
            {/^Bra[sz]il$/.test(oAddress.locl_ccountry) ? (
              <Select
                name="locl_ccity"
                className="selectCustom"
                classNamePrefix="react-select"
                // string vazia para renderizar o placeholder
                value={oAddress.locl_ccity ? {
                  label: oAddress.locl_ccity,
                  value: oAddress.locl_ccity
                } : ''}
                onChange={(oSelectedOption) => {
                  setAddress((oPrevAddress) => ({
                    ...oPrevAddress,
                    locl_ccity: oSelectedOption.value,
                    locl_cibgecode: oSelectedOption.ibge,
                  }));
                }}
                options={aBrazilCities}
                placeholder={intl.get('Address.ccidade')}
                noOptionsMessage={() => intl.get('sem_opcoes')}
                required
              />
            ) : (
              <Form.Control
                type="text"
                name="locl_ccity"
                value={oAddress.locl_ccity ?? ''}
                onChange={handleAddressChange}
                maxLength={250}
                placeholder={intl.get('Address.ccidade')}
                required
              />
            )}
          </Form.Group>
        </div>

        {/* *********** Botões *********** */}
        <Form.Group
          className="buttons"
        >
          <Button
            type="button"
            className="btn alternate back btn btn-outline-secondary"
           onClick={handleHide}>
            {intl.get('cancelar')}
          </Button>
          <Button
            type="submit"
            className={"btn btn-secondary" + (bIsSubmitting ? ' disabled' : '')}
            disabled={bIsSubmitting}
          >
            {intl.get('salvar')}
          </Button>
        </Form.Group>
      </Form>
    </ModalBody>
  );
}

export default Customer;
