import React from 'react';
import intl from 'react-intl-universal';
import SweetAlert from 'react-bootstrap-sweetalert';

import cImageDownload from './img/icon-download.svg';
import { downloadAttachment } from '../../actions/ActionDocumentDetails';

const AttachmentModal = ({ oAttachment, onClose = () => {} }) => {
  if (oAttachment == null) return null;

  const handleClose = () => {
    onClose();
  };

  const cExt = oAttachment.atch_cname.split('.').pop();

  return (
    <SweetAlert
			customClass="modal-edit modalMedium scrollBar"
			title=""
      showConfirm={false}
      onConfirm={handleClose}
      onCancel={handleClose}
      closeOnClickOutside
		>
			<div className="head-modal">
        {intl.get("DocumentEditor.view_attachment")}
        <span
          role="button"
          tabIndex="0"
          aria-labelledby="head-modal"
          onClick={() => downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname)}
          className="attachment-download"
        >
          <img src={cImageDownload} />
        </span>
        <span
          role="button"
          tabIndex="0"
          aria-labelledby="head-modal"
          onKeyDown={handleClose}
          onClick={handleClose}
          className="close"
          href=""
        />
      </div>
      {/^(png|jpe?g|bmp)$/i.test(cExt) ? (
        <img className="attachment-image" src={oAttachment.atch_clocal} />
      ) : (
        <iframe src={oAttachment.atch_clocal} style={{ width: '100%', height: '500px' }}></iframe>
      )}
		</SweetAlert>
  )
};

export default AttachmentModal;