import React, { Component } from 'react';

import intl from 'react-intl-universal';
import moment from 'moment';
import { formatDateToUserTZ } from '../../../../utils/time';
import EncodedLocalStorage from '../../../../config/EncodedLocalStorage';

import './styles.scss';

const oLocalStorage = new EncodedLocalStorage();

class DateFilters extends Component {
  constructor(oProps) {
    super(oProps);
    moment.locale(oLocalStorage.get('cLanguage'));
    this.state = {
      currentDateFilterShortcut: 'all',
      oCurrentDates: {
        startDate: '',
        endDate: ''
      },
      request_status: 'all'
    };

    this.DATE_FILTER_SHORTCUTS = [
      {
        label: intl.get('Customers.Applications_tokens.logs.filter.date.last_7_days'),
        value: 'last_7_days'
      },
      {
        label: intl.get('Customers.Applications_tokens.logs.filter.date.last_15_days'),
        value: 'last_15_days'
      },
      {
        label: intl.get('Customers.Applications_tokens.logs.filter.date.last_30_days'),
        value: 'last_30_days'
      },
      {
        label: intl.get('Customers.Applications_tokens.logs.filter.date.all'),
        value: 'all'
      },
      {
        label: intl.get('Customers.Applications_tokens.logs.filter.date.custom'),
        value: 'custom'
      }
    ];
  }

  formateDate = dDate => {
    return moment
      .parseZone(dDate)
      .tz(oLocalStorage.get('cTimezone'))
      .format('YYYY-MM-DD');
  };

  handleDateFilterChange = dateFilterValue => {
    const dToday = this.formateDate(new Date(), this.cUserTimeZone);

    const dates = {
      last_7_days: {
        startDate: this.formateDate(moment().subtract(7, 'd')),
        endDate: dToday
      },
      last_15_days: {
        startDate: this.formateDate(moment().subtract(15, 'd')),
        endDate: dToday
      },
      last_30_days: {
        startDate: this.formateDate(moment().subtract(30, 'd')),
        endDate: dToday
      },
      all: {
        startDate: '',
        endDate: ''
      },
      custom: {
        startDate: dToday,
        endDate: dToday
      }
    };

    this.setState({
      currentDateFilterShortcut: dateFilterValue,
      oCurrentDates: dates[dateFilterValue]
    });

    this.props.handleChange(dates[dateFilterValue]);
  };

  handleOnChangeCustomDate = e => {
    const value = e.target.value;
    const name = e.target.name;

    const dates = { ...this.state.oCurrentDates, [name]: value };
    this.setState({ oCurrentDates: dates });
    this.props.handleChange(dates);
  };

  render() {
    const { currentDateFilterShortcut, oCurrentDates } = this.state;
    const isCustom = currentDateFilterShortcut === 'custom';

    return (
      <div className="date-filter-container">
        <span className="date-filter-title">{intl.get('Customers.Applications_tokens.logs.filter.date.period')}</span>
        <div className="export-logs-container">
          <div className="buttons-container">
            {this.DATE_FILTER_SHORTCUTS.map(({ label, value }) => (
              <button
                type="button"
                title={label}
                key={value}
                className={`${value === currentDateFilterShortcut && 'active'}`}
                onClick={() => this.handleDateFilterChange(value)}
              >
                {label}
              </button>
            ))}
          </div>
          {isCustom && (
            <div className="input-container">
              <label>
                {intl.get('Customers.Applications_tokens.logs.filter.date.start_date')}
                <input
                  onChange={this.handleOnChangeCustomDate}
                  name="startDate"
                  type="date"
                  required
                  defaultValue={oCurrentDates?.startDate}
                />
              </label>

              <label>
                {intl.get('Customers.Applications_tokens.logs.filter.date.end_date')}
                <input
                  onChange={this.handleOnChangeCustomDate}
                  name="endDate"
                  type="date"
                  required
                  defaultValue={oCurrentDates?.endDate}
                />
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DateFilters;
