import React, { Component } from 'react';
import Froala from "froala-editor";
import ReactDOM from 'react-dom';
import 'froala-editor/js/froala_editor.pkgd.min';
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/js/languages/pt_br';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import '../assets/libs/font-awesome/css/font-awesome.css';
import Button from 'react-bootstrap/Button';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
//import '../assets/scss/froala-custom.scss';
import FroalaEditor from 'react-froala-wysiwyg';
import Axios from 'axios';
import fLoading from '../assets/img/preloader-black.gif';
import fGpt from '../assets/img/chatGpt.png';
//import url from '../config/Url';
import EncodedLocalStorage from '../config/EncodedLocalStorage';
import { Alert } from '../components/Alert';
import zIndex from '@material-ui/core/styles/zIndex';
import url from '../config/Url';

export default class FroalaEditorComponent extends Component {
  constructor(oProps) {
    super(oProps);
    this.config = oProps.config;

    this.handleModelChange = this.handleModelChange.bind(this);
    const oLocalStorage = new EncodedLocalStorage();
    this.state = {
      model: oProps.model,
      bHasGptKey: oLocalStorage.get('cApiGpt') === 1,
      alertMessage: '',
      isLoading: false,
      cErrorModalMsg: '',
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.saveApiKey = this.saveApiKey.bind(this);
  }

  saveApiKey = (cNewApiKey) => {
    this.setState({ bHasGptKey: true });
  };

  componentDidMount() {
    const { fnRef } = this.props;
    let customTranslations = {
      "Text Color": "Cor do texto",
      "Background Color": "Cor de fundo",
      "Inline Class": "Classe Inline",
      Default: "Padrão",
      "Lower Alpha": "Alfa minúsculo",
      "Lower Greek": "Grego minúsculo",
      "Lower Roman": "Roman minúsculo",
      "Upper Alpha": "Alfa maiúsculo",
      "Upper Roman": "Roman maiúscula",
      Circle: "Círculo",
      Disc: "Disco",
      Square: "Quadrado",
      Single: "Único",
      Double: "Duplo",
      "Insert Files": "Inserir arquivos",
      "Download PDF": "Baixar PDF",
      "Gray": "Cinzento",
      "Bordered": "Limitado",
      "Spaced": "Espaçamento",
      "Uppercase": "Caixa-alta",
    };
    let pt = Froala.LANGUAGE.pt_br.translation;
    for (var p in customTranslations) pt[p] = customTranslations[p];

    if (fnRef !== null) {
      fnRef(this.elRef);
    }

    Froala.DefineIconTemplate('copilot_gpt', '<img src=[SRC] alt=[ALT] style="width: 90px;height: 28px;margin-top: auto;margin-bottom: auto;border-radius: 5px;"/>');
    Froala.DefineIcon('imageIcon', { SRC: fGpt, ALT: 'Copilot GPT', template: 'copilot_gpt' });
    Froala.RegisterCommand("chatGPT", {
      title: "Copilot GPT",
      icon: 'imageIcon',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: () => {
        const { bHasGptKey } = this.state;

        if (!bHasGptKey) {
          this.setState({
            alertMessage: intl.get('ChatGpt.api_mensagem')
          });
          return;
        }

        const editorInstance = this.elRef.getEditor();
        const selection = editorInstance.selection.text();

        this.setState({ isLoading: true });

        Axios.patch("/customer/handle-gpt-request", {
          selection: selection
        }, {
          headers: {
            "Content-Type": "application/json",
          }
        })
          .then(response => {
            this.setState({ isLoading: false });
            const data = response.data;
            if (data.cResult) {
              const responses = data.cResult.join('<br><br>'); // Join the responses with HTML line breaks
              if (editorInstance) {
                editorInstance.html.insert(selection + '<br>' + responses); // Insert the joined responses into the editor
              }
            } else {
              console.error('Erro desconhecido ao solicitar resposta do ChatGPT.');
            }
          })
          .catch(error => {
            console.error('Erro ao solicitar resposta do ChatGPT:', error);
            this.setState({ isLoading: false });

            // Verifica se o erro é uma resposta da API com a estrutura esperada
            if (error.response && error.response.data && error.response.data.cError) {
              const errorMessage = error.response.data.cError;
              const errorDetail = error.response.data.cDetails;
              // Insere a mensagem de erro no Froala Editor
              if (editorInstance) {
                editorInstance.html.insert(`<p><strong>Error:</strong> ${errorMessage}</p><br>`);
                editorInstance.html.insert(`<p> ${errorDetail}</p>`);
              }
            } else {
              // Se o erro não seguir a estrutura esperada, exibe uma mensagem genérica
              if (editorInstance) {
                editorInstance.html.insert('<p><strong>Error:</strong> Falha na comunicação com o ChatGPT. Por favor, tente novamente mais tarde.</p>');
              }
            }
          });
      },
    });

  }

  handleModelChange(cModel) {
    this.setState({
      model: cModel
    });
  }

  hideAlert = () => {
    this.setState({
      cErrorModalMsg: '',
      alertMessage: '',
    });
  };

  render() {
    const { fnHandleChange, bSimpleEditor, cTypeComment, bReadOnly } = this.props;
    const { alertMessage, model, isLoading, bHasGptKey, cErrorModalMsg } = this.state;

    this.config['language'] = ["pt_br"];
    this.config['fontSizeUnit'] = 'pt';
    this.config['attribution'] = false;
    this.config['documentReady'] = true;
    this.config['charCounterCount'] = false;
    this.config['videoAllowedAttributes'] = ['src', 'width', 'height', 'controls', 'poster', 'source', 'type', 'style', 'class', 'name', 'id'];
    this.config['videoAllowedTypes'] = ['mp4'];
    this.config['videoUploadMethod'] = 'POST';
    this.config['videoMaxSize'] = 80 * 1024 * 1024; // 80MB
    this.config['events'] = {
      'video.error': (evtVidErr) => {
        if (evtVidErr.code === 5) {
          this.setState({ cErrorModalMsg: intl.get('DocumentEditor.erro_upload_video_dimensoes') })
        }
      },
      'paste.beforeCleanup': function (clipboardHtml) {
        clipboardHtml = clipboardHtml.replace(/<div[^>]*?style="[^"]*?height:\s*\d+\.?\d*px;?[^"]*?"[^>]*?>/g, function (match) {
          return match.replace(/height:\s*\d+\.?\d*px;?/g, '');
        });

        clipboardHtml = clipboardHtml.replace(/<span [^>]*?style="[^"]*?(font-family)[^"]*?"[^>]*?>/g, '<span style="font-family: Helvetica, Arial, sans-serif;">');

        return clipboardHtml;
      },
      initialized() {
        if (bReadOnly) {
          this.edit.off();
        }
      },
      'image.beforeRemove': function (img) {
        const imageUrl = img.attr('src');

        if (!imageUrl) {
          console.log("Imagem não encontrada.");
          return;
        }

        const cPath = imageUrl.replace(`${url.cBase}`, '');
        Axios.delete(cPath);
      },
      'video.beforeRemove': function (video) {
        const videoElement = video[0].firstChild;
        const videoUrl = videoElement ? videoElement.currentSrc : null;

        if (!videoUrl) {
          console.log("Video não encontrada.");
          return;
        }

        const cPath = videoUrl.replace(`${url.cBase}`, '');
        Axios.delete(cPath);
      }
    }
    this.config['key'] = process.env.REACT_APP_FROALA_LICENSE_KEY;
    if (bReadOnly) {
      this.config['listAdvancedTypes'] = false;
      this.config['toolbarButtons'] = [];
    } else if (bSimpleEditor) {
      this.config['listAdvancedTypes'] = false;
      this.config['toolbarBottom'] = true;
      this.config['height'] = 200;
      if (cTypeComment === 'X') {
        this.config['toolbarButtons'] = {
          "moreMisc": {
            "buttons": [
              'fullscreen', 'bold', 'italic', 'underline'
            ],
            'align': 'left',
            'buttonsVisible': 4
          },
          'moreText': {
            'buttons': [
              'chatGPT', 'formatOL', 'formatUL', 'fontFamily', 'paragraphFormat', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', "quote"
            ],
            'buttonsVisible': 3
          }
        };
      } else {
        this.config['toolbarButtons'] = {
          "moreMisc": {
            "buttons": [
              'chatGPT', 'fullscreen', 'bold', 'italic', 'underline', 'insertLink', 'insertImage', 'insertVideo'
            ],
            'align': 'left',
            'buttonsVisible': 4
          },
          'moreText': {
            'buttons': [
              'formatOL', 'formatUL', 'fontFamily', 'paragraphFormat', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', "quote"
            ],
            'buttonsVisible': 4
          }
        };
      }
    } else {
      this.config['listAdvancedTypes'] = true;
    }

    return (
      <div>
        <FroalaEditor
          ref={elRef => {
            this.elRef = elRef;
          }}
          model={model}
          onModelChange={evt => {
            this.handleModelChange(evt);
            fnHandleChange(evt);
          }}
          config={this.config}
          typingTimer={1000}
        />
        {cErrorModalMsg && (
          <SweetAlert
            error
            title=""
            onCancel={this.hideAlert}
            onConfirm={this.hideAlert}
            closeOnClickOutside
            allowEscape
            showConfirm
          >
            {cErrorModalMsg}
          </SweetAlert>
        )}
        {/* caso o modal de erro de upload dispare, não mostra o modal gpt enquanto o usuário não fechar o do erro */
          (!cErrorModalMsg && alertMessage) && (
            <GptModal
              bHasGptKey={bHasGptKey}
              fnOnClose={this.hideAlert}
              fnOnSave={this.saveApiKey}
              cMessage={alertMessage}
            />
          )}
        {isLoading && (
          <div style={{
            position: 'fixed', // Usa 'fixed' para cobrir toda a tela
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay semitransparente
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999 // Garante que esteja na frente de tudo
          }}>
            <img src={fLoading} alt="Carregando..." />
          </div>
        )}
      </div>
    );
  }
}

FroalaEditorComponent.defaultProps = {
  config: {
    useClasses: true,
    attribution: false
  },
  model: null,
  elEditor: null,
  fnRef: null,
  bReadOnly: false,
};


class GptModal extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      cApiKey: '',
      bHasApiKey: oProps.bHasApiKey,
      bIsSending: false,
      cAlertMsg: '',
    }
    this.hideAlert = this.hideAlert.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleApiKeyChange = this.handleApiKeyChange.bind(this);
  }

  hideAlert = () => {
    this.setState({ cAlertMsg: '' });
  }

  onClose = () => {
    const { fnOnClose } = this.props;
    fnOnClose();
  }

  handleApiKeyChange = (evtChange) => {
    this.setState({ cApiKey: evtChange.target.value });
  }

  onSave = async () => {
    const { cApiKey, bIsSending } = this.state;
    const { fnOnSave } = this.props;

    if (bIsSending) return;
    this.setState({ bIsSending: true });

    try {
      await Axios.patch("/customer/update-api-key", {
        cust_ckeyapigpt: JSON.stringify({ cApiKey })
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('cStorageToken')}`,
        },
      });
      localStorage.setItem('cApiGpt', 1);
      this.setState({
        cAlertMsg: '',
        bHasApiKey: localStorage.getItem('cApiGpt') === '1',
      });
      fnOnSave(cApiKey);
      this.onClose();
      //  console.log("Configurações da API GPT salvas com sucesso!");
    } catch (oError) {
      //  console.error("Erro ao salvar as configurações da API GPT:", error);
      const cAlertMsg = oError.response?.data?.cError ??
        "Erro desconhecido ao salvar a chave da API";
      this.setState({
        cApiKey: '',
        bHasApiKey: localStorage.getItem('cApiGpt') == '1',
        cAlertMsg,
        bIsSending: false,
      });
    }
  }

  render() {
    // listados todos os props que aceita aqui para saber quais são
    const { cMessage, /* bHasApiKey, fnOnSave, fnOnClose */ } = this.props;
    const { cAlertMsg, bIsSending, cApiKey } = this.state;

    return (
      <SweetAlert
        customClass="modal-edit md ScrollBar"
        title=""
        onCancel={this.onClose}
        onConfirm={this.onClose}
        showConfirm={false}
        closeOnClickOutside
        allowEscape
      >
        <div className="head-modal" style={{ marginTop: '-38px' }}>
          {intl.get('ChatGpt.titulo_api')}
          <span
            role="button"
            tabIndex="0"
            aria-labelledby="head-modal"
            onKeyPress={this.onClose}
            onClick={this.onClose}
            className="close"
            href=""
          />
        </div>
        {cAlertMsg && (
          <Alert type="error" message={cAlertMsg} onCloseAlert={this.hideAlert}>
            {cAlertMsg}
          </Alert>
        )}
        {cMessage}
        <div>
          <br />
          <input
            type="text"
            className="form form-control"
            style={{
              display: 'block',
              width: '100%',
              height: '40px',
              padding: '0 15px',
              border: '1px solid #ccc',
              borderRadius: '3px',
              marginTop: '5px',
              backgroundColor: '#fff',
              fontSize: '16px',
              fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
              color: '#777',
              backgroundColor: '#fff',
              transition: 'all 200ms'
            }}
            onChange={this.handleApiKeyChange}
            value={cApiKey}
            placeholder="Digite a chave da API"
          />
          <br />
        </div>
        <div>
          <Button variant="success" href="https://platform.openai.com/api-keys" target='_blank'>{intl.get('ChatGpt.obter_chave')}</Button>
          <Button variant="info" href="https://openai.com/pricing" target='_blank'>{intl.get('ChatGpt.ver_planos')}</Button>
          <Button variant="primary" disabled={bIsSending} onClick={this.onSave}>{intl.get('ChatGpt.salvar_api')}</Button>
        </div>
      </SweetAlert>
    );
  }
}
