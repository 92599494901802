import React from 'react';

import './styles.scss';

const Drawer = props => {
  const { isOpen, title, className, children, onClose, ...rest } = props;

  const handleOnClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    isOpen && (
      <>
        <div className="drawer-overlay" onClick={handleOnClose} />
        <div className={`drawer ${className || ''}`} {...rest}>
          <div className="drawer-header">
            {title && <h2 className="drawer-header-title">{title}</h2>}
            <span
              role="button"
              tabIndex="0"
              href="#"
              title="Fechar"
              aria-label="Fechar"
              className="close-drawer"
              onClick={handleOnClose}
            />
          </div>

          <div className="drawer-content">{children}</div>
        </div>
      </>
    )
  );
};

export default Drawer;
