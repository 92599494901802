import React from 'react';

export default function SearchIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7803 18.7197L15.5925 14.532C16.4714 13.4233 17 12.0245 17 10.5C17 6.91015 14.0898 4 10.5 4C6.91015 4 4 6.91015 4 10.5C4 14.0898 6.91015 17 10.5 17C12.0245 17 13.4234 16.4714 14.5319 15.5925L18.7197 19.7803C18.8662 19.9267 19.0581 20 19.25 20C19.4419 20 19.6338 19.9267 19.7803 19.7803C20.0732 19.4873 20.0732 19.0127 19.7803 18.7197ZM5.5 10.5C5.5 7.74303 7.74297 5.5 10.5 5.5C13.257 5.5 15.5 7.74303 15.5 10.5C15.5 13.257 13.257 15.5 10.5 15.5C7.74297 15.5 5.5 13.257 5.5 10.5Z"
        fill={props?.fill || '#ACB4BA'}
      />
    </svg>
  );
}
