import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { toast, Button, Flex, Typography } from '@stardust-ds/react';
import Axios from '../../../../config/Axios';
import './styles.scss';
import '../DateFilter/styles.scss';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import DownloadIcon from '../../../../assets/icons/DownloadIcon';
import EncodedLocalStorage from '../../../../config/EncodedLocalStorage';
import DateFilters from '../DateFilter/index';

const oLocalStorage = new EncodedLocalStorage();

class ModalApplicationLogs extends Component {
  constructor(oProps) {
    super(oProps);
    moment.locale(oLocalStorage.get('cLanguage'));
    this.state = {
      aLogs: [],
      bLoading: false,
      bIsSubmited: false,
      startDate: '',
      endDate: '',
      requestStatus: 'all'
    };
  }

  handleCloseModal = () => {
    const { bLoading } = this.state;
    if (bLoading) return;

    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }

    this.setState({
      startDate: '',
      endDate: '',
      requestStatus: 'all',
      aLogs: [],
      bIsSubmited: false
    });
  };

  handleFetchLogs = () => {
    const { oApplication } = this.props;
    const { startDate, endDate, requestStatus } = this.state;
    this.setState({ bIsSubmited: true });

    if (new Date(endDate) < new Date(startDate)) {
      toast({
        type: 'error',
        title: 'Exportar Logs',
        description: ''
      });
      return;
    }

    this.setState({ bLoading: true });
    const hasStatus = requestStatus !== 'all' ? { request_status: requestStatus } : {};
    const params = {
      start_date: startDate,
      end_date: endDate,
      ...hasStatus
    };

    Axios.get(`/customerapplication-logs/${oApplication?.nid}`, {
      params
    })
      .then(response => {
        const aLogs = response?.data?.data || [];
        if (!aLogs.length) {
          this.handleCreateCSV('');
          this.setState({ bLoading: false });
          return;
        }
        const aLogsFormatted = aLogs?.map(oLog => {
          return {
            'Data e Hora': moment
              .parseZone(oLog?.dcreated)
              .tz(oLocalStorage.get('cTimezone'))
              .format('DD/MM/YYYY  HH:mm:ss'),
            Url: oLog?.curi,
            Metodo: oLog.cmethod,
            Status: oLog.nstatus,
            Body: JSON.stringify(oLog?.request_body),
            Response: JSON.stringify(oLog?.response),
          };
        });

        const aKeys = Object.keys(aLogsFormatted[0]);
        const sCSV = aLogsFormatted.map(oLog => aKeys.map(sKey => `${sKey}: ${oLog[sKey]}`).join('\n')).join('\n\n\n');

        this.handleCreateCSV(sCSV);
        this.setState({ bLoading: false });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        toast({
          type: 'error',
          title: 'Exportar Logs',
          description: oError?.response?.data?.message || 'Erro ao exporar logs'
        });
      });
  };

  handleCreateCSV = sCSV => {
    const { oApplication } = this.props;

    const oBlob = new Blob([sCSV], { type: 'text/csv' });
    const sURL = window.URL.createObjectURL(oBlob);
    const oLink = document.createElement('a');
    oLink.href = sURL;
    oLink.download = `${oApplication?.cname}_logs.csv`;
    oLink.click();
  };

  handeChangeFilterDate = oDates => {
    this.setState({
      startDate: oDates.startDate,
      endDate: oDates.endDate
    });
  };

  handleChangeFilterStatus = sStatus => {
    this.setState({
      requestStatus: sStatus
    });
  };

  render() {
    const { bLoading, bIsSubmited, requestStatus, startDate, endDate } = this.state;
    const { isOpen } = this.props;

    if (!isOpen) return null;

    const bIsStartDateBiggerEndDate = new Date(endDate) < new Date(startDate);

    return (
      <div className="modal-application-logs-overlay">
        <div className="modal-application-logs-container">
          <div className="modal-application-logs-header">
            <h3>{intl.get('Customers.Applications_tokens.logs.title')}</h3>
            <span className="modal-application-logs-close" onClick={this.handleCloseModal}>
              &times;
            </span>
          </div>
          <div className="modal-application-logs-body">
            <div className="export-logs-container" style={{ marginBottom: 24 }}>
              <label>{intl.get('Customers.Applications_tokens.logs.filter.export')}</label>
              <div className="buttons-container">
                <button
                  type="button"
                  className={`${requestStatus === 'all' && 'active'}`}
                  onClick={() => this.handleChangeFilterStatus('all')}
                >
                  {intl.get('Customers.Applications_tokens.logs.filter.all')}
                </button>
                <button
                  type="button"
                  className={`${requestStatus === 'success' && 'active'}`}
                  onClick={() => this.handleChangeFilterStatus('success')}
                >
                  {intl.get('Customers.Applications_tokens.logs.filter.success')}
                </button>
                <button
                  type="button"
                  className={`${requestStatus === 'error' && 'active'}`}
                  onClick={() => this.handleChangeFilterStatus('error')}
                >
                  {intl.get('Customers.Applications_tokens.logs.filter.error')}
                </button>
              </div>
            </div>

            <DateFilters handleChange={this.handeChangeFilterDate} />
            {bIsSubmited && bIsStartDateBiggerEndDate && (
              <Typography color="#FF3541" type="p3">
                {intl.get('Customers.Applications_tokens.logs.filter.date.invalid_dates')}
              </Typography>
            )}

            <Button
              type="button"
              bgColor="#6CB6E3"
              isFullWidth
              isLoading={bLoading}
              onClick={this.handleFetchLogs}
              className="csv-download-button"
            >
              <Flex width="100%" alignItems="center" justifyContent="center" gap="8px">
                <DownloadIcon />
                {intl.get('Customers.Applications_tokens.logs.download')}
              </Flex>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalApplicationLogs;
