import React from 'react';

export default function CopyIcon(props) {
  const { stroke, strokeWidth, ...rest } = props;

  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M4.66664 3.86699V2.37366C4.66664 1.77627 4.66664 1.47757 4.7829 1.2494C4.88517 1.0487 5.04835 0.885517 5.24905 0.783252C5.47723 0.666992 5.77592 0.666992 6.37331 0.666992H10.4266C11.024 0.666992 11.3227 0.666992 11.5509 0.783252C11.7516 0.885517 11.9148 1.0487 12.0171 1.2494C12.1333 1.47757 12.1333 1.77627 12.1333 2.37366V6.42699C12.1333 7.02438 12.1333 7.32308 12.0171 7.55125C11.9148 7.75195 11.7516 7.91513 11.5509 8.0174C11.3227 8.13366 11.024 8.13366 10.4266 8.13366H8.93331M3.17331 11.3337H7.22664C7.82403 11.3337 8.12273 11.3337 8.3509 11.2174C8.55161 11.1151 8.71479 10.952 8.81705 10.7512C8.93331 10.5231 8.93331 10.2244 8.93331 9.62699V5.57366C8.93331 4.97627 8.93331 4.67757 8.81705 4.4494C8.71479 4.2487 8.55161 4.08552 8.3509 3.98325C8.12273 3.86699 7.82403 3.86699 7.22664 3.86699H3.17331C2.57592 3.86699 2.27723 3.86699 2.04905 3.98325C1.84835 4.08552 1.68517 4.2487 1.5829 4.4494C1.46664 4.67757 1.46664 4.97627 1.46664 5.57366V9.62699C1.46664 10.2244 1.46664 10.5231 1.5829 10.7512C1.68517 10.952 1.84835 11.1151 2.04905 11.2174C2.27723 11.3337 2.57592 11.3337 3.17331 11.3337Z"
        stroke={stroke || "#0066FF"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth || "1"}
      />
    </svg>
  );
}
