import React from 'react';

export default function PlusIcon(props) {
  const { isRounded = true } = props

  if (!isRounded) {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M7.00002 1.66699V12.3337M1.66669 7.00033H12.3334"
          stroke="#415364"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...props}
        />
      </svg>
    );
  }

  return (
    <svg
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12C16 12.2548 15.7933 12.4615 15.5385 12.4615H12.4615V15.5385C12.4615 15.7933 12.2548 16 12 16C11.7452 16 11.5385 15.7933 11.5385 15.5385V12.4615H8.46154C8.20673 12.4615 8 12.2548 8 12C8 11.7452 8.20673 11.5385 8.46154 11.5385H11.5385V8.46154C11.5385 8.20673 11.7452 8 12 8C12.2548 8 12.4615 8.20673 12.4615 8.46154V11.5385H15.5385C15.7933 11.5385 16 11.7452 16 12Z"
        fill={props.fill || "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.6667C15.6819 18.6667 18.6667 15.6819 18.6667 12C18.6667 8.3181 15.6819 5.33333 12 5.33333C8.3181 5.33333 5.33333 8.3181 5.33333 12C5.33333 15.6819 8.3181 18.6667 12 18.6667ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
        fill={props.fill || "white"}
      />
    </svg>
  )
}
