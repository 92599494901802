import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import './styles.scss';

const ModalDelete = props => {
  const { isOpen, title, description, onClose, onConfirm, isLoading, ...rest } = props;

  const [cPassword, setCPassword] = useState('');
  const [bShowPassword, setBShowPassword] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setCPassword('');
      setBShowPassword(false);
    }
  }, [isOpen]);

  const handleCloseModal = () => {
    if (isLoading) return

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleOnConfirm = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isLoading) return
    
    if (typeof onConfirm === 'function') {
      onConfirm(cPassword);
    }
  };

  const handleOnChangePassword = e => {
    setCPassword(e.target.value);
  };

  const handleToggleShowPassword = () => {
    setBShowPassword(!bShowPassword);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-delete-overlay">
      <div className="modal-delete-container" {...rest}>
        <div className="modal-delete-header">
          <h3>{title}</h3>
          <span className="modal-delete-close" onClick={handleCloseModal}>
            &times;
          </span>
        </div>
        <form onSubmit={handleOnConfirm}>
          <div className="modal-delete-body">
            <p className="modal-delete-description">{description}</p>
            <div className="modal-delete-password">
              <p className="modal-delete-description bold">{intl.get('ModalDelete.type_password')}</p>
              <label>
                {intl.get('csenha')}
                <div>
                  <input
                    type={bShowPassword ? 'text' : 'password'}
                    required
                    value={cPassword}
                    onChange={handleOnChangePassword}
                  />
                  <i onClick={handleToggleShowPassword} className={bShowPassword ? 'icon-eye-opened' : 'icon-eye-closed'} />
                </div>
              </label>
            </div>
          </div>
          <div className="modal-delete-btn-container">
            <button type="button" className="modal-delete-btn cancel" onClick={handleCloseModal} disabled={isLoading}>
              {intl.get('cancel')}
            </button>
            <button type="submit" className="modal-delete-btn delete" disabled={isLoading}>
              {intl.get('ModalDelete.button_delete')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalDelete;
